<template>
  <div>
    <!-- <div class="d-flex justify-content-end mb-1">
      <div>
        <router-link to="/create-order">
          <b-button
            variant="primary"
            size="md"
          >
            <span class="align-middle">Create New Order</span>
          </b-button>
        </router-link>
      </div>
    </div> -->
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Orders">
        <b-row
          class="d-flex justify-content-end"
        >
          <b-col
            md="6"
            class="my-1 mx-0 px-0  d-flex justify-content-end "
            sm="4"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="md"
              label-for="filterInput"
              class="mb-0t mr-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          :items="items"
          :busy.sync="isBusy"
          :fields="fields"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          striped
          responsive
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(total_time)="data">
            {{ data.value }} min
          </template>
          <template #cell(total_amount)="data">
            &#163;{{ data.value }}
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="getStatusVariant(data.value)"
            >
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <router-link :to="'/order/' + data.item.id">
                <b-button
                  variant="success"
                  class="btn-icon"
                >
                  View Details
                </b-button>
              </router-link>
            </div>
          </template>
        </b-table>
        <b-card-footer class="d-flex">
          <b-row class="w-100">
            <b-col
              md="6"
            >
              <b-form-group
                label-cols="4"
                label-cols-md="2"
                label="Per Page"
                label-for="perpage"
              >
                <b-form-select
                  id="perpage"
                  v-model="perPage"
                  :options="option"
                  class="w-md-25 w-25"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <div class="d-flex justify-content-md-end justify-content-start">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                />
              </div>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const ordersModule = createNamespacedHelpers('orders')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: ['id',
        { key: 'total_amount', label: 'Total Price' },
        { key: 'total_time', label: 'Total Time' },
        { key: 'assignee.name', label: 'Assigned To' },
        { key: 'repair_date', label: 'Repair Date' },
        { key: 'payment_type', label: 'Payment Type' },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
      // Per Page & Pagination
      perPage: 20,
      currentPage: 1,
      option: [
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      totalRows: 1,
      // Filter
      filter: '',
    }
  },
  async mounted() {
    await this.getOrders()
  },
  methods: {
    ...ordersModule.mapActions(['FETCH_ALL_ORDERS']),
    async getOrders() {
      try {
        this.isBusy = true
        this.items = await this.FETCH_ALL_ORDERS()
        this.totalRows = this.items.length
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 'Waiting':
          return 'warning'
        case 'In House':
          return 'primary'
        case 'Cancelled':
          return 'danger'
        case 'Done':
          return 'success'

        default:
          break
      }
      return 'danger'
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>

</style>
Waiting
